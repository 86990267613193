<template>
  <tm-popup
    :submit="onSubmit"
    title="إضافة حساب جديد"
    ref="popup"
  >
    <div class="vx-row mi-format-align-justify">
      <div class="vx-col sm:w-1/2 w-full">
        <tm-input
          name="الاسم الكامل"
          type="text"
          placeholder="الاسم الكامل"
          validation-rules="required"
          v-model="user.fullName"
          class="w-full"
        />
      </div>
      <div class="vx-col sm:w-1/2 w-full">
        <tm-input
          name="رقم الموبايل"
          type="text"
          placeholder="رقم الموبايل"
          :validation-rules="{ required: true, regex: /^((9639)|(09))[0-9]{8}$/ }"
          v-model="user.phoneNumber"
          class="w-full"
        />
      </div>
      <div class="vx-col sm:w-1/2 w-full">
        <tm-select
          :options="typeOptions"
          :reduce="name => name.value"
          class="w-full"
          label="name"
          name="نوع الحساب"
          v-model="user.accountType"
          validation-rules="required"
        />
      </div>
      <div class="vx-col sm:w-1/2 w-full" v-if="user.accountType === 'RIYAD'">
        <tm-input
          name="اسم النادي"
          type="text"
          placeholder="اسم النادي"
          validation-rules="required"
          v-model="user.clubName"
          class="w-full"
        />
      </div>
      <div class="vx-col sm:w-1/2 w-full" v-if="user.accountType === 'RIYAD'">
        <tm-input
          name="معرف المستخدم"
          type="number"
          placeholder="معرف المستخدم"
          validation-rules="required"
          v-model="user.referenceId"
          class="w-full"
        />
      </div>
    </div>
  </tm-popup>
</template>

<script>
  import {UserService} from "@/app/rihab-alhabib/services/user-service/user.service";
  import TmPopup from "../../../shared/shared-components/TmPopup";
  import TmInput from "../../../shared/shared-components/TmInput";
  import TmSelect from "../../../shared/shared-components/TmSelect";

  export default {
    name: "StorePopup",
    data() {
      return {
        typeOptions: [
          {
            name: 'موظفين',
            value: 'EMPLOYEE'
          },
          {
            name: 'نوادي',
            value: 'RIYAD'
          }
        ],
      };
    },

    props: {
      user: {
        type: Object,
        required: true
      },
    },

    methods: {
      onSubmit() {
        if (this.user.id == null)
          this.create();
        else
          this.update();
      },
      async create() {
        let response = await UserService.storeUser(this.user);
        if(!response)
          this.$refs.popup.endLoading();
        this.$refs.popup.close();
        this.$emit("created");
      },
      async update() {
         await UserService.updateUser(this.user);
          this.$refs.popup.close();
          this.$emit("updated");
      },
      open() {
        this.$refs.popup.open();
      },
    },
    components: {
      TmPopup,
      TmInput,
      TmSelect
    },
  };
</script>

<style scoped>

</style>
