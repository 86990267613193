





























































import AgTable from '@/app/shared/shared-components/ag-grid/AgTableServerSide.vue';
import AgTableBtnCell from "@/app/shared/shared-components/ag-grid/AgTableBtnCell.vue";
import StorePopup from './storePopup.vue';
import ImportExcel from "../../../../components/excel/ImportExcel.vue";
import Vue from "vue";
import utilities from "@/app/shared/utilities";
import {ColDef, GridApi, GridOptions} from "ag-grid-community";
import {isArray} from "lodash";
import {SubProductService} from "@/app/ecommerce/services/sub-product-service/sub-product.service";
import {UserModel} from "@/app/rihab-alhabib/services/user-service/interfaces/user.model";
import {UserService} from "@/app/rihab-alhabib/services/user-service/user.service";
import {GetUsersResponse} from "@/app/rihab-alhabib/services/user-service/interfaces/get-users-response";
import {GetUserDetailsResponse} from "@/app/rihab-alhabib/services/user-service/interfaces/get-user-details-request.dto";

export default Vue.extend({
  name: "users",
  data() {
    return {
      gridApi: {} as GridApi | null | undefined,
      gridOptions: {} as GridOptions,
      user: {
        id: undefined,
        fullName: '',
        phoneNumber: '',
        accountType: '',
        clubName: '',
        referenceId: undefined,
      } as UserModel,
      permissions: {
        add: 'create_rihab_user',
        delete: 'delete_rihab_user',
        edit: 'update_rihab_user',
        export:'export_rihab_users',
        import:'import_rihab_users'
      },
      popupActive: false,
      popupActiveDetails: false,
      title: '',
      prayers: {} as GetUserDetailsResponse,
    };
  },
  methods: {
    createRecord() {
      utilities.initObjectMembers(this.user);
      (this.$refs?.storePopupRef as any).open();
    },
    editRecord(user: UserModel) {
      this.user = user;
      (this.$refs?.storePopupRef as any).open();
    },
    async deleteUser(ids: number | number[]) {
      let idsToDelete = ids.toString();
      await UserService.deleteUser(idsToDelete);
      this.refreshStore();
    },
    refreshStore() {
      this.gridApi?.refreshServerSideStore({});
    },
    async getAll(query: string): Promise<GetUsersResponse> {
       return  UserService.getAll(query);
    },
    createColDefs(): Array<ColDef> {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          suppressSizeToFit: true,
          headerCheckboxSelection: true,
          checkboxSelection: true,
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'الاسم الكامل',
          field: 'fullName',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'رقم الموبايل',
          field: 'phoneNumber',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'نوع الحساب',
          field: 'accountType',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'اسم النادي',
          field: 'clubName',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'معرف المستخدم',
          field: 'referenceId',
          filter: 'agNumberColumnFilter'
        },
        {
          headerName: 'الإجراءات',
          field: 'id',
          cellRendererFramework: AgTableBtnCell,
          sortable: false,
          cellRendererParams: {
            deleteRecord: function (id: number, rowIndex: number) {
              self.deleteUser(id);
            },
            editRecord: function (user: UserModel) {
              self.editRecord(user);
            },
            viewRecordDetails: function (user: UserModel) {
              self.viewRecordDetails(user.id as number);
            },
            actions: ['view','edit', 'delete'],
            permissions: self.permissions
          },
        },
      ];
    },
    async importUsers(excelData: any) {
      await UserService.importUsers(excelData.rawFile);
      await UserService.getAll('');
    },
    async exportUsers() {
      await UserService.exportUsers();
    },
    async viewRecordDetails(id: number){
      this.popupActiveDetails = true;
      this.prayers = await UserService.getUser(id);
      let totalPrayers = this.prayers.total!== null? this.prayers.total : 0;
      this.title = 'عدد الصلوات الكلية : ' + totalPrayers;
    },
  },
  components: {
    AgTable,
    StorePopup,
    ImportExcel
  },
  created() {
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
});
