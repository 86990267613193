import {httpClient} from "@/app/shared/shared-services/httpClient";
import {environment} from "@/environment";
import {UserModel} from "@/app/rihab-alhabib/services/user-service/interfaces/user.model";
import {CreateUserRequestDto} from "@/app/rihab-alhabib/services/user-service/interfaces/create-user-request.dto";
import {UpdateUserRequestDto} from "@/app/rihab-alhabib/services/user-service/interfaces/update-user-request.dto";
import {GetUsersResponse} from "@/app/rihab-alhabib/services/user-service/interfaces/get-users-response";
import {GetUserDetailsResponse} from "@/app/rihab-alhabib/services/user-service/interfaces/get-user-details-request.dto";

const URL = `${environment.rihabAlhabibApiBaseUrl}users`;
export const UserService = {
  async getAll(query: string): Promise<GetUsersResponse> {
    const url = URL + query;
    return (await httpClient.get(url)).data;
  },
  async storeUser(user: UserModel): Promise<UserModel> {
    const userReqDto = this.toCreateUserReqDto(user);
    return (await httpClient.post(URL, userReqDto)).data.data;
  },
  async updateUser(user: UserModel): Promise<UserModel> {
    const userReqDto = this.toUpdateUserReqDto(user);
    return (await httpClient.put(`${URL}/${userReqDto.id}`, userReqDto)).data.data;
  },
  async deleteUser(userId: string): Promise<void> {
    return httpClient.delete(`${URL}/${userId}`);
  },
  async getUser(userId: number): Promise<GetUserDetailsResponse> {
    return (await httpClient.get(`${URL}/${userId}`)).data;
  },
  async importUsers(excelData: any) {
    const url = `${URL}/import`;
    let excelFile = new FormData();
    excelFile.append('file', excelData);
    await httpClient.post(url, excelFile);
  },
  async exportUsers() {
    const url = `${URL}/export`;
    const response = await httpClient.get(url, {responseType: 'arraybuffer'});
    let fileURL = window.URL.createObjectURL(new Blob([response.data]));
    let fileLink = document.createElement('a');
    fileLink.href = fileURL;
    fileLink.setAttribute('download', 'users.csv');
    document.body.appendChild(fileLink);
    fileLink.click();
  },
  async reportPrayers(params: string) {
    const url = `${environment.rihabAlhabibApiBaseUrl}prayers/reports?${params}`;
    const response = await httpClient.get(url, {responseType: 'arraybuffer'});
    let fileURL = window.URL.createObjectURL(new Blob([response.data]));
    let fileLink = document.createElement('a');
    fileLink.href = fileURL;
    fileLink.setAttribute('download', 'prayersReport.csv');
    document.body.appendChild(fileLink);
    fileLink.click();
  },
  toCreateUserReqDto(UserModel: UserModel): CreateUserRequestDto {
    return {
      fullName: UserModel.fullName,
      phoneNumber: UserModel.phoneNumber,
      accountType: UserModel.accountType,
      clubName: UserModel.clubName,
      referenceId: UserModel.referenceId === null ? undefined : Number(UserModel.referenceId),
    };
  },

  toUpdateUserReqDto(UserModel: UserModel): UpdateUserRequestDto {
    return {
      id: UserModel.id!,
      fullName: UserModel.fullName,
      phoneNumber: UserModel.phoneNumber,
      accountType: UserModel.accountType,
      clubName: UserModel.clubName,
      referenceId: UserModel.referenceId === null ? undefined : Number(UserModel.referenceId),
    };
  }
};
